/*
 * Tactile-JS
 * Copyright 2018 Craig S. Kaplan, csk@uwaterloo.ca
 *
 * Distributed under the terms of the 3-clause BSD license.  See the
 * file "LICENSE" for more information.
 */
'use strict'
import { cross } from 'mathjs'

const EdgeShape = {
	J : 10001,
	U : 10002,
	S : 10003,
	I : 10004,
};

const numTypes = 81;

function mul( A, B ) 
{
	if( B.hasOwnProperty( 'x' ) ) {
		// Matrix * Point
		return { 
			x : A[0]*B.x + A[1]*B.y + A[2],
			y : A[3]*B.x + A[4]*B.y + A[5] };
	} else {
		// Matrix * Matrix
		return [A[0]*B[0] + A[1]*B[3], 
			A[0]*B[1] + A[1]*B[4],
			A[0]*B[2] + A[1]*B[5] + A[2],

			A[3]*B[0] + A[4]*B[3], 
			A[3]*B[1] + A[4]*B[4],
			A[3]*B[2] + A[4]*B[5] + A[5]];
	}
};

function matchSeg( p, q )
{
	return [q.x-p.x, p.y-q.y, p.x,  q.y-p.y, q.x-p.x, p.y];
};
// 'use strict'

const tilingTypes = [
	1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 61, 62, 64, 66, 67, 68, 69, 71, 72, 73, 74, 76, 77, 78, 79, 81, 82, 83, 84, 85, 86, 88, 90, 91, 93
];


const tiling_type_data = (function() {
const es_00 = [ EdgeShape.J, EdgeShape.J, EdgeShape.J ];
const es_01 = [ EdgeShape.S, EdgeShape.J, EdgeShape.S, EdgeShape.S, EdgeShape.S ];
const es_02 = [ EdgeShape.S, EdgeShape.J, EdgeShape.J, EdgeShape.S ];
const es_03 = [ EdgeShape.S, EdgeShape.J, EdgeShape.S, EdgeShape.J ];
const es_04 = [ EdgeShape.S, EdgeShape.S, EdgeShape.S ];
const es_05 = [ EdgeShape.S, EdgeShape.J ];
const es_06 = [ EdgeShape.J ];
const es_07 = [ EdgeShape.S ];
const es_08 = [ EdgeShape.U, EdgeShape.J ];
const es_09 = [ EdgeShape.U, EdgeShape.S, EdgeShape.S ];
const es_10 = [ EdgeShape.J, EdgeShape.I ];
const es_11 = [ EdgeShape.S, EdgeShape.I, EdgeShape.S ];
const es_12 = [ EdgeShape.I, EdgeShape.J ];
const es_13 = [ EdgeShape.I, EdgeShape.S ];
const es_14 = [ EdgeShape.U ];
const es_15 = [ EdgeShape.I ];
const es_16 = [ EdgeShape.S, EdgeShape.J, EdgeShape.J ];
const es_17 = [ EdgeShape.J, EdgeShape.J, EdgeShape.I ];
const es_18 = [ EdgeShape.S, EdgeShape.S, EdgeShape.J, EdgeShape.S ];
const es_19 = [ EdgeShape.S, EdgeShape.S, EdgeShape.J, EdgeShape.I ];
const es_20 = [ EdgeShape.J, EdgeShape.J, EdgeShape.S ];
const es_21 = [ EdgeShape.S, EdgeShape.I, EdgeShape.I ];
const es_22 = [ EdgeShape.J, EdgeShape.I, EdgeShape.I ];
const es_23 = [ EdgeShape.J, EdgeShape.J ];
const es_24 = [ EdgeShape.I, EdgeShape.I ];
const es_25 = [ EdgeShape.J, EdgeShape.S ];
const es_26 = [ EdgeShape.S, EdgeShape.S, EdgeShape.S, EdgeShape.S ];
const es_27 = [ EdgeShape.J, EdgeShape.S, EdgeShape.S ];
const es_28 = [ EdgeShape.I, EdgeShape.S, EdgeShape.I, EdgeShape.S ];
const es_29 = [ EdgeShape.J, EdgeShape.I, EdgeShape.S ];
const es_30 = [ EdgeShape.I, EdgeShape.I, EdgeShape.I, EdgeShape.S ];
const es_31 = [ EdgeShape.S, EdgeShape.S ];
const es_32 = [ EdgeShape.S, EdgeShape.I ];
const es_33 = [ EdgeShape.U, EdgeShape.I ];
const es_34 = [ EdgeShape.U, EdgeShape.S ];
const es_35 = [ EdgeShape.I, EdgeShape.I, EdgeShape.I ];
const es_36 = [ EdgeShape.I, EdgeShape.S, EdgeShape.I ];
const es_37 = [ EdgeShape.I, EdgeShape.S, EdgeShape.S ];

const esi_00 = [ 0, 1, 2, 0, 1, 2 ];
const esi_01 = [ 0, 0, 1, 2, 2, 1 ];
const esi_02 = [ 0, 1, 0, 2, 1, 2 ];
const esi_03 = [ 0, 1, 2, 3, 1, 4 ];
const esi_04 = [ 0, 1, 2, 2, 1, 3 ];
const esi_05 = [ 0, 1, 2, 3, 1, 3 ];
const esi_06 = [ 0, 0, 1, 1, 2, 2 ];
const esi_07 = [ 0, 1, 1, 0, 1, 1 ];
const esi_08 = [ 0, 0, 0, 0, 0, 0 ];
const esi_09 = [ 0, 1, 2, 0, 2, 1 ];
const esi_10 = [ 0, 1, 0, 0, 1, 0 ];
const esi_11 = [ 0, 1, 2, 2, 1, 0 ];
const esi_12 = [ 0, 1, 1, 1, 1, 0 ];
const esi_13 = [ 0, 1, 1, 2, 2 ];
const esi_14 = [ 0, 0, 1, 2, 1 ];
const esi_15 = [ 0, 1, 2, 3, 2 ];
const esi_16 = [ 0, 1, 2, 1, 2 ];
const esi_17 = [ 0, 1, 1, 1, 1 ];
const esi_18 = [ 0, 1, 2, 0 ];
const esi_19 = [ 0, 1, 1, 0 ];
const esi_20 = [ 0, 0, 0, 0 ];
const esi_21 = [ 0, 1, 0 ];
const esi_22 = [ 0, 1, 0, 1 ];
const esi_23 = [ 0, 1, 0, 2 ];
const esi_24 = [ 0, 0, 1, 1 ];
const esi_25 = [ 0, 1, 2, 3 ];
const esi_26 = [ 0, 0, 1, 2 ];
const esi_27 = [ 0, 1, 2 ];
const esi_28 = [ 0, 0, 1 ];
const esi_29 = [ 0, 0, 0 ];

const eo_00 = [ false, false, false, false, false, false, false, true, false, true, false, true ];
const eo_01 = [ false, false, true, true, false, false, false, false, true, true, false, true ];
const eo_02 = [ false, false, false, false, true, true, false, false, false, true, true, true ];
const eo_03 = [ false, false, false, false, false, false, false, false, false, true, false, false ];
const eo_04 = [ false, false, false, false, false, false, true, true, false, true, false, false ];
const eo_05 = [ false, false, false, false, false, false, false, false, true, true, true, true ];
const eo_06 = [ false, false, false, true, false, false, false, true, false, false, false, true ];
const eo_07 = [ false, false, false, false, false, false, false, false, false, false, false, false ];
const eo_08 = [ false, false, false, false, true, true, false, false, false, false, true, true ];
const eo_09 = [ false, false, false, false, true, true, false, true, false, true, true, false ];
const eo_10 = [ false, false, false, false, false, false, false, true, true, false, true, false ];
const eo_11 = [ false, false, false, false, true, true, false, true, true, false, true, false ];
const eo_12 = [ false, false, false, false, false, false, true, false, true, false, true, false ];
const eo_13 = [ false, false, false, false, false, true, true, true, true, false, true, false ];
const eo_14 = [ false, false, false, false, true, false, false, false, false, false, true, false ];
const eo_15 = [ false, false, false, false, false, true, false, false, false, true ];
const eo_16 = [ false, false, true, true, false, false, false, false, false, true ];
const eo_17 = [ false, false, false, false, false, false, false, false, false, true ];
const eo_18 = [ false, false, true, false, false, false, false, false, true, false ];
const eo_19 = [ false, false, false, false, false, false, true, true, true, true ];
const eo_20 = [ false, false, false, false, false, true, true, true, true, false ];
const eo_21 = [ false, false, false, false, false, false, false, true ];
const eo_22 = [ false, false, false, false, false, true, false, true ];
const eo_23 = [ false, false, false, false, true, false, true, false ];
const eo_24 = [ false, false, false, true, false, false, false, true ];
const eo_25 = [ false, false, true, false, true, true, false, true ];
const eo_26 = [ false, false, true, false, false, false, true, false ];
const eo_27 = [ false, false, false, false, false, true ];
const eo_28 = [ false, false, false, false, true, false ];
const eo_29 = [ false, false, false, false, false, true, false, false ];
const eo_30 = [ false, false, false, false, false, true, true, true ];
const eo_31 = [ false, false, true, true, false, false, true, true ];
const eo_32 = [ false, false, false, false, true, true, false, false ];
const eo_33 = [ false, false, false, false, false, false, false, false ];
const eo_34 = [ false, false, false, false, true, true, true, true ];
const eo_35 = [ false, false, true, true, false, false, false, false ];
const eo_36 = [ false, false, false, true, false, false, false, false ];
const eo_37 = [ false, false, false, false, false, true, true, false ];
const eo_38 = [ false, false, false, false, true, false, false, false ];
const eo_39 = [ false, false, true, true, false, true, true, false ];
const eo_40 = [ false, false, false, true, true, true, true, false ];
const eo_41 = [ false, false, false, false, false, false ];
const eo_42 = [ false, false, true, true, false, false ];
const eo_43 = [ false, false, false, true, false, false ];
const eo_44 = [ false, false, true, false, false, false ];

const dp_00 = [ 0.12239750492, 0.5, 0.143395479017, 0.625 ];
const dp_01 = [ 0.12239750492, 0.5, 0.225335752741, 0.225335752741 ];
const dp_02 = [ 0.12239750492, 0.5, 0.225335752741, 0.625 ];
const dp_03 = [ 0.12239750492, 0.5, 0.315470053838, 0.5, 0.315470053838, 0.5 ];
const dp_04 = [ 0.12239750492, 0.5, 0.225335752741, 0.225335752741, 0.5 ];
const dp_05 = [ 0.12239750492, 0.5, 0.225335752741, 0.625, 0.5 ];
const dp_06 = [ 0.6, 0.196416770201 ];
const dp_07 = [ 0.12239750492, 0.5, 0.225335752741 ];
const dp_08 = [  ];
const dp_09 = [ 0.12239750492, 0.225335752741 ];
const dp_10 = [ 0.12239750492, 0.225335752741, 0.5 ];
const dp_11 = [ 0.12239750492, 0.225335752741, 0.225335752741 ];
const dp_12 = [ 0.216506350946 ];
const dp_13 = [ 0.104512294489, 0.65 ];
const dp_14 = [ 0.230769230769, 0.5, 0.225335752741 ];
const dp_15 = [ 0.230769230769, 0.5, 0.225335752741, 0.5 ];
const dp_16 = [ 0.230769230769, 0.225335752741 ];
const dp_17 = [ 0.141304, 0.465108, 0.534891 ];
const dp_18 = [ 0.452827026611, 0.5 ];
const dp_19 = [ 0.366873818946 ];
const dp_20 = [ 0.230769230769 ];
const dp_21 = [ 0.230769230769, 0.5 ];
const dp_22 = [ 0.5, 0.102564102564 ];
const dp_23 = [ 0.230769230769, 0.869565217391 ];
const dp_24 = [ 0.5, 0.230769230769, 0.5, 0.5 ];
const dp_25 = [ 0.230769230769, 0.5, 0.230769230769 ];
const dp_26 = [ 0.5, 0.5, 0.6 ];
const dp_27 = [ 0.5, 0.102564102564, 0.102564102564 ];
const dp_28 = [ 0.230769230769, 0.230769230769 ];
const dp_29 = [ 0.5 ];
const dp_30 = [ 0.105263157895 ];
const dp_31 = [ 0.196416770201 ];
const dp_32 = [ 0.5, 0.196416770201 ];

const tvc_00 = [
	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0, 0.1, 0, 5, 0, 0, -2.5, 3.9, 0, 5.5, 0, -0.4, 0, 5, 0, -4, 0.5, 3.9, 0, 0, 0, 0.1, 0, 5, 0, 0, -1.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, -5.5, 0, 0.5, 0, 0, 0, 4, -2 ];
const tvc_01 = [
	3.9, 0, 0, 0, 0.1, 0, 5, 0, 0, -2.5, 3.9, 0, 0, 3.5, -0.4, 0, 5, 0, 0, -2, 3.9, 0, 0, 0, 0.1, 0, 5, 0, 0, -1.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, -3.5, 0, 0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
const tvc_02 = [
	0, 0, -3.5, 0, 0.5, 0, 0, 0, 4, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0, 0.1, 0, 5, 0, 0, -2.5, 3.9, 0, 3.5, 0, -0.4, 0, 5, 0, 4, -4.5, 3.9, 0, 0, 0, 0.1, 0, 5, 0, 0, -1.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1 ];
const tvc_03 = [
	0, 0, -2.5, 0, 0, 0, 0.5, 0, 0, 0, 3, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0, 0, 0, 0.1, 0, 5, 0, 0, 0, 0, -2.5, 3.9, 0, 0, 0, 2.5, 0, -0.4, 0, 5, 0, 0, 0, 3, -3.5, 3.9, 0, 0, 0, 0, 0, 0.1, 0, 5, 0, 0, 0, 0, -1.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1 ];
const tvc_04 = [
	3.9, 0, 0, 3.5, 0, -0.4, 0, 5, 0, 0, 5, -4.5, 3.9, 0, 0, 0, 0, 0.1, 0, 5, 0, 0, 0, -1.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, -3.5, 0, 0, 0.5, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0, 0, 0.1, 0, 5, 0, 0, 0, -2.5 ];
const tvc_05 = [
	3.9, 0, 3.5, 0, 0, -0.4, 0, -5, 0, 4, 0, 0.5, 3.9, 0, 0, 0, 5, -2.4, 0, 5, 0, 0, 0, -1.5, 0, 0, 0, 0, 5, -2.5, 0, 0, 0, 0, 0, 1, 0, 0, -3.5, 0, 0, 0.5, 0, 0, 0, 4, 0, -2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0, 0, 0.1, 0, -5, 0, 0, 0, 2.5 ];
const tvc_06 = [
	0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, -0.288675134595, 0, 0, 1, 0, 0, 0, 2.5, 1.12583302492, -0.721132486541, -1.44337567297, 1.95, 1.06036297108, 5, 0, -2.5, 0, 3.9, 0.1, 2.5, -1.12583302492, -1.27886751346, 1.44337567297, 1.95, -0.671687836487 ];
const tvc_07 = [
	0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0.1, 0, 5, 0, -2.5, 3.9, 0, 3.5, -0.4, 0, 5, 0, -2, 3.9, 0, 0, 0.1, 0, 5, 0, -1.5, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, -3.5, 0.5, 0, 0, 0, 0.5 ];
const tvc_08 = [
	1, 0, 0.5, 0.866025403784, -0.5, 0.866025403784, -1, 0, -0.5, -0.866025403784, 0.5, -0.866025403784 ];
const tvc_09 = [
	0, 0, 0, 0, 0, 0, 3.9, 0, 0.1, 0, 0, 0, 3.9, 3.5, -0.4, 0, 0, 0.5, 3.9, 0, 0.1, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, -3.5, 0.5, 0, 0, 0.5 ];
const tvc_10 = [
	0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0.1, 0, 0, 0, 0, 3.9, 3.5, 0, -0.4, 0, 0, 5, -2, 3.9, 0, 0, 0.1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, -3.5, 0, 0.5, 0, 0, 5, -2 ];
const tvc_11 = [
	3.9, 3.5, -0.4, 0, 0, 0.5, 3.9, 0, 0.1, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, -3.5, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 3.9, 0, 0.1, 0, 0, 0 ];
const tvc_12 = [
	0, -3.5, 0, 0.5, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0.1, 0, 0, 0, 0, 3.9, 0, 3.5, -0.4, 0, 0, 0, 0.5, 3.9, 0, 0, 0.1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1 ];
const tvc_13 = [
	0, 0.5, 0, -0.288675134595, 0, 1, 0, 0, 1.15470053838, 0.75, 2, 0.144337567297, 0, 0.5, 4, 0, -1.15470053838, 0.25, 2, 0.144337567297, 0, 0, 0, 0 ];
const tvc_14 = [
	0, 0, 1, 0, 0, 0, 0, 5, -2.5, 5.1, 0, -0.1, -1.47224318643, 2.5, -1.22113248654, 2.55, 1.44337567297, -0.771687836487, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, -0.866025403784 ];
const tvc_15 = [
	3.9, 0, 0, 0.1, 0, 5, 0, -2.5, 3.9, 0, 3.5, -0.4, 0, 5, 0, -2, 3.9, 0, 0, 0.1, 0, 5, 0, -1.5, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0 ];
const tvc_16 = [
	3.9, 0, 0, 0, 0.1, 0, 5, 0, 0, -2.5, 3.9, 0, 3.5, 0, -0.4, 0, 5, 0, 4, -4, 3.9, 0, 0, 0, 0.1, 0, 5, 0, 0, -1.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];
const tvc_17 = [
	3.9, 0, 0.1, 0, 0, 0, 3.9, 3.5, -0.4, 0, 0, 0.5, 3.9, 0, 0.1, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0 ];
const tvc_18 = [
	0, 0, 5, -2.5, 0, 0, 0, 1, 0, 0, -5, 2.5, 0, 10, 0, -4, 0, 0, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0.1, 0, -5, 0, 2.5, 3.9, 0, 5, -2.4, 0, 5, 0, -1.5 ];
const tvc_19 = [
	0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1.95, 2.5, -0.95, -1.95, 2.5, -1.05, 3.9, 0, 0.1, 0, 5, -2, 1.95, -2.5, 1.55, 1.95, 2.5, -0.45 ];
const tvc_20 = [
	0, -1, 0, 0, 0, 1, 0, 0, 4.95, 0.55, 4.95, 0.55, 0, 0, 9.9, 0.1, -4.95, -0.55, 4.95, 0.55 ];
const tvc_21 = [
	0, 1, 0, 0, 2.925, 0.075, 1.68874953738, 0.0433012701892, 0, 0, 0, 0, -2.925, 1.425, 1.68874953738, -0.822724133595 ];
const tvc_22 = [
	1, 0, 0.75, 0.433012701892, 0, 0, 0.75, -0.433012701892 ];
const tvc_23 = [
	0.5, 0, 0, 0.866025403784, -0.5, 0, 0, -0.866025403784 ];
const tvc_24 = [
	0, 0.57735026919, -1, 0, 1, 0 ];
const tvc_25 = [
	0, 0, 0, 0, 0, 0, 3.9, 0, 0.1, 0, 5, -2.5, 3.9, 0, 0.1, 0, 5, -1.5, 0, 0, 0, 0, 0, 1 ];
const tvc_26 = [
	5, 0, -2, 0, -3.9, -0.1, 0, 0, 1, 0, 0, 0, 5, 0, -2, 0, 3.9, 0.1, 0, 0, 0, 0, 0, 0 ];
const tvc_27 = [
	0, 0, 1, 0, 0, 0, 0, -3.45, 4, 3.9, 0, 0.1, 0, 3.45, -3, 3.9, 0, 0.1, 0, 0, 0, 0, 0, 0 ];
const tvc_28 = [
	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 5, 0, 0, 0, -1.5, 0, 3.9, 0, 0, 0.1, 0, 0, 5, 0, -2.5, 0, 0, 0, 5, -1.5 ];
const tvc_29 = [
	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -5, 3.9, 2.6, 3.9, 0, 0, 0.1, 0, -5, 0, 2.5, 3.9, 0, 0, 0.1 ];
const tvc_30 = [
	0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, -10, 0, 0, 5, 0, 10, 0, -4, 10, 0, 10, -10, 0, 10, 0, -5, 0, 0, 10, -5 ];
const tvc_31 = [
	0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 3.9, 0.1, 0, 0, 3.9, 0.1 ];
const tvc_32 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 3.9, 0.1, 0, 0, 0, 0, 0, 0, 0, 0, 5, 0, 0, -2, 0, -3.9, 0, -0.1 ];
const tvc_33 = [
	0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 3.9, 0, 0.1, 0, 0, 0, 3.9, 0, 0.1, 0, 3.9, 0.1 ];
const tvc_34 = [
	1, 0, 1, 1, 0, 1, 0, 0 ];
const tvc_35 = [
	1.8, 0.1, 0, 0, 0, 1, 0, 1, 0, 0, -1.8, 1.9, 0, 0, 0, 0 ];
const tvc_36 = [
	3.8, 0.1, 0, 0, 0, 0, -3.8, 0.9, -3.8, -0.1, 0, 0, 0, 0, 3.8, -0.9 ];
const tvc_37 = [
	0, 0, 0.57735026919, 0, 0, 1 ];
const tvc_38 = [
	0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 3.9, 0.1 ];
const tvc_39 = [
	0.5, 0.5, 0, 0, 1, 0 ];
const tvc_40 = [
	0, 1, 0, 0, 0, 0.5, 3.9, 0.1, 0, 0, 0, 0 ];
const tvc_41 = [
	0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 5, 0, -2, 0, 3.9, 0.1 ];
const tvc_42 = [
	1, 0, -0.5, 0.866025403784, -0.5, -0.866025403784 ];

const tc_00 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 3.9, 0, 5.5, 0, -0.4, 0, 5, 0, -4, -0.5 ];
const tc_01 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 7.8, 0, 3.5, 3.5, -0.8, 0, 0, 0, 0, 0 ];
const tc_02 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, -7.8, 0, -7, 0, 0.8, 0, 0, 0, 0, -1 ];
const tc_03 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, -7.8, 0, -2.5, 0, -2.5, 0, 0.8, 0, -10, 0, 3, 0, -3, 4 ];
const tc_04 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, -15.6, 0, -7, -7, 0, 1.6, 0, 0, 0, 0, 0, -2 ];
const tc_05 = [ 0, 0, 0, 0, 0, 0, 0, 10, 0, 0, 0, -3, 7.8, 0, 7, 0, 0, -0.8, 0, 0, 0, 0, 0, 0 ];
const tc_06 = [ -2.5, -3.37749907476, 0.663397459622, 4.33012701892, -1.95, -3.08108891325, -2.5, 3.37749907476, 2.33660254038, -4.33012701892, -1.95, 2.11506350946 ];
const tc_07 = [ 0, 0, 0, 0, 0, 0, 0, -1, 7.8, 0, 7, -0.8, 0, 0, 0, 0 ];
const tc_08 = [ 1.5, 0.866025403784, 1.5, -0.866025403784 ];
const tc_09 = [ 1.5, 0.866025403784, 0, 1.73205080757 ];
const tc_10 = [ 0, 0, 0, 0, 0, -1, 3.9, 3.5, -0.4, 0, 0, -0.5 ];
const tc_11 = [ 0, 0, 0, 0, 0, 0, 0, -1, 7.8, 7, 0, -0.8, 0, 0, 0, 0 ];
const tc_12 = [ 3.9, 3.5, -0.4, 0, 0, 0.5, 3.9, 3.5, -0.4, 0, 0, -0.5 ];
const tc_13 = [ 0, 0, 0, 0, 0, 0, 0, -1, -7.8, -3.5, -3.5, 0.8, 0, 0, 0, 0 ];
const tc_14 = [ 0, 0, -4, -0.866025403784, 3.46410161514, 0.75, -2, -0.433012701892 ];
const tc_15 = [ 4.4167295593, -2.5, 2.66339745962, -2.55, -4.33012701892, 1.34903810568, 0, -5, 2.5, -5.1, 0, -1.63205080757 ];
const tc_16 = [ -7.8, 0, -3.5, 0.3, 0, 0, 0, -0.5, -7.8, 0, -3.5, 0.3, 0, 0, 0, 0.5 ];
const tc_17 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 7.8, 0, 3.5, 0, -0.3, 0, 10, 0, 4, -7.5 ];
const tc_18 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 15.6, 0, 7, 0, -0.6, 0, 0, 0, 0, 0 ];
const tc_19 = [ 0, 0, 0, 0, 0, 0, 0, 1, -15.6, 0, -7, 0.6, 0, 0, 0, 0 ];
const tc_20 = [ 0, 0, 0, 0, 0, 1, -7.8, -3.5, 0.3, 0, 0, 0.5 ];
const tc_21 = [ 0, 0, 0, 0, 0, 10, 0, -3, -7.8, 0, -10, 4.8, 0, 0, 0, 0 ];
const tc_22 = [ -3.9, 5, -3.1, -3.9, -5, 1.9, -3.9, -5, 1.9, 3.9, -5, 3.1 ];
const tc_23 = [ 9.9, 1.1, -9.9, -1.1, -9.9, -1.1, -9.9, -1.1 ];
const tc_24 = [ 0, 0, 0, 1.73205080757, 0, 1.5, 0, -0.866025403784 ];
const tc_25 = [ -1.5, 0.866025403784, -1.5, -0.866025403784 ];
const tc_26 = [ 0, 1.73205080757, 1.5, -0.866025403784 ];
const tc_27 = [ -1, 1.73205080757, 1, 1.73205080757 ];
const tc_28 = [ 1, 1.73205080757, -1, 1.73205080757 ];
const tc_29 = [ 1, 1.73205080757, 2, 0 ];
const tc_30 = [ 0, 0, 0, 0, 0, -1, 3.9, 0, 0.1, 0, 5, -2.5 ];
const tc_31 = [ 0, 0, 0, 0, 0, -1, 7.8, 0, 0.2, 0, 0, 0 ];
const tc_32 = [ 0, 0, 0, 0, -7.8, -0.2, 0, 0, 1, 0, 0, 0 ];
const tc_33 = [ 0, -6.9, 8, 0, 0, 0, 0, -3.45, 4, -3.9, 0, -0.1 ];
const tc_34 = [ -5, 0, -5, 0, 5, 0, -3.9, 0, -5, 1.4, -5, 0, 0, 0, 1.5, 0, -3.9, 0, 0, -0.1 ];
const tc_35 = [ 0, 0, 0, 0, 0, -1, 7.8, 0, 0.2, 0, 10, -5 ];
const tc_36 = [ 0, 0, 0, 0, -7.8, 0, 0, -0.2, 0, 0, 3.9, 1.1, 0, 0, 0, 0 ];
const tc_37 = [ -15.6, 0, -0.4, 0, 0, 0, 0, 0, 0, 0, 0, -1 ];
const tc_38 = [ 0, 0, 0, 0, -20, 0, -20, 20, 0, 0, 0, -2, 0, 0, 0, 0 ];
const tc_39 = [ 0, 2, 0, 0, 0, 0, -7.8, -0.2 ];
const tc_40 = [ 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, -7.8, -7.8, -0.4 ];
const tc_41 = [ -7.8, 0, -0.2, 0, 0, 0, -3.9, 0, -0.1, 0, 3.9, 1.1 ];
const tc_42 = [ 0, 2, 2, 0 ];
const tc_43 = [ 0, 0, 0, 4, 0, -2, 0, 2 ];
const tc_44 = [ 0, 0, -7.6, 1.8, 7.6, 0.2, -7.6, 1.8 ];
const tc_45 = [ 1, 1, 1, -1 ];
const tc_46 = [ 1, 0, 0, 1 ];
const tc_47 = [ 0, 0, -3.9, -0.1, 0, 1, 0, 0 ];
const tc_48 = [ 0, 0, -3.9, -0.1, 0, 2, 0, 0 ];
const tc_49 = [ 0, -3.45, 4, -3.9, 0, -0.1, 0, -3.45, 4, 3.9, 0, 0.1 ];
const tc_50 = [ 3.8, 0.1, -3.8, 0.9, -3.8, -0.1, -3.8, 0.9 ];
const tc_51 = [ 0, 2, -1.73205080757, 1 ];
const tc_52 = [ 0, 2, 0, 0, 0, 1, 3.9, 0.1 ];
const tc_53 = [ 0, 1, -1, 0 ];
const tc_54 = [ -1, 1, -2, 0 ];
const tc_55 = [ 0, 1, 1, 0 ];
const tc_56 = [ 0, 0.5, -3.9, -0.1, 0, -0.5, -3.9, -0.1 ];
const tc_57 = [ -5, 0, 2, 0, -3.9, -0.1, -5, 0, 3, 0, -3.9, -0.1 ];
const tc_58 = [ 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 7.8, 0.2 ];
const tc_59 = [ 0, 1, 0, 0, 0, 0, 7.8, 0.2 ];
const tc_60 = [ -1.5, 2.59807621135, -3, 0 ];
const tc_61 = [ 0, -0.5, -3.9, -0.1, 0, 0.5, -3.9, -0.1 ];

const ac_00 = [
	0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0 ];
const ac_01 = [
	0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 7.8, 0, 0, 3.5, -0.3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, -0.5 ];
const ac_02 = [
	0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -3.9, 0, -3.5, 0, 0.4, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 5, 0, 4, -4.5 ];
const ac_03 = [
	0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, -2.5, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 3, 0, 0, -1 ];
const ac_04 = [
	0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 7.8, 0, 0, 3.5, 0, -0.3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 10, 0, 0, 5, -6, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, -3.5, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -0.5, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, -7.8, 0, -3.5, -3.5, 0, 0.8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 10, 0, 0, 5, -7.5 ];
const ac_05 = [
	0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 7.8, 0, 3.5, 0, 5, -2.8, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 4, 0, -1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 3.9, 0, 0, 0, 5, -2.4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 5, 0, 0, 0, -1.5, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 3.9, 0, 3.5, 0, 0, -0.4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, -5, 0, 4, 0, 0.5 ];
const ac_06 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -0.5, 0, 0, -0.866025403784, 0, 0, 0.5, 0, 0, 0.866025403784, 0, 0, -0.5, 0, 0, -0.866025403784, 0, 0, -0.5, 0, 0, 0.866025403784, 0, 0, 1, 0, 0, -0.866025403784, 0, 0, -0.5, 0, 0, 0 ];
const ac_07 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 7.8, 0, 3.5, -0.3, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -0.5 ];
const ac_08 = [
	1, 0, 0, 0, 1, 0 ];
const ac_09 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0 ];
const ac_10 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 7.8, 3.5, 0, -0.3, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 5, -2 ];
const ac_11 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, -3.5, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0.5 ];
const ac_12 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0.5, 0, 0.866025403784, 0, 0.5, 0, 0.866025403784, 0, -0.5, 0, -0.866025403784, 0, -0.5, 0, -0.866025403784, 0, 0.5, 0, 0.866025403784, 0, -0.5, 0, -0.866025403784 ];
const ac_13 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0.5, 0, 0, 0.866025403784, 0, 0, 1, 0, 0, -0.866025403784, 0, 0, 0.5, 0, 0, 0, 0, 0, -0.5, 0, 0, 0.866025403784, 0, 0, 1.5, 0, 0, -0.866025403784, 0, 0, -0.5, 0, 0, -0.866025403784, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, -1.73205080757, 0, 0, -0.5, 0, 0, -0.866025403784, 0, 0, 0, 0, 0, 0.866025403784, 0, 0, -0.5, 0, 0, -1.73205080757, 0, 0, 0.5, 0, 0, -0.866025403784, 0, 0, -0.5, 0, 0, 0.866025403784, 0, 0, 0.5, 0, 0, -0.866025403784 ];
const ac_14 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0 ];
const ac_15 = [
	0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 7.8, 0, 3.5, 0, -0.3, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 10, 0, 4, -6.5 ];
const ac_16 = [
	0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 7.8, 0, 3.5, 0, -0.3, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 10, 0, 4, -6.5, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 15.6, 0, 7, 0, -0.6, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 7.8, 0, 3.5, 0, -0.3, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 10, 0, 4, -6.5 ];
const ac_17 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0, 0, 0, -7.8, 0, -3.5, 0.3, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -0.5, 0, 0, 0, 1, 0, 0, 0, 0, -7.8, 0, -3.5, 0.3, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0.5 ];
const ac_18 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 1 ];
const ac_19 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 10, 0, -3, 0, 0, 0, 1, 0, 0, 0, 0, -3.9, 0, -5, 2.4, 0, 0, 0, 0, 0, 0, 0, -1, 0, 5, 0, -1.5, 0, 0, 0, -1, 0, 0, 0, 0, 3.9, 0, 5, -2.4, 0, 0, 0, 0, 0, 0, 0, 1, 0, 5, 0, -1.5 ];
const ac_20 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 1, 0, 0, 0, 0, 0, -1, -3.9, 0, -0.1, 0, 0, 1, 0, 0, 0, 0, -5, 3, 0, 0, 0, 0, 0, 1, -3.9, 0, -1.1, 0, 0, -1, 0, 0, 0, 0, -5, 3 ];
const ac_21 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, -1, 9.9, 1.1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, -9.9, -1.1, 0, 1, 0, 0, 0, 0 ];
const ac_22 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -0.5, 0, 0.866025403784, 0, 1.5, 0, -0.866025403784, 0, -0.5, 0, 0.866025403784, 0, -0.5, 0, -0.866025403784, 0, 1.5, 0, 0.866025403784, 0, -0.5, 0, -0.866025403784, 0, 0.5, 0, 0.866025403784, 0, 0, 0, 0.866025403784, 0, -0.5, 0, 0, 0, -1, 0, 0, 0, 1.5, 0, 0, 0, 1, 0, 0.866025403784, 0, 0.5, 0, -0.866025403784, 0, 0, 0, -0.866025403784, 0, -0.5, 0, 1.73205080757 ];
const ac_23 = [
	1, 0, 0, 0, 1, 0, 0.5, -0.866025403784, 0, 0.866025403784, 0.5, 0, -0.5, -0.866025403784, 0, 0.866025403784, -0.5, 0, -1, 0, 0, 0, -1, 0, -0.5, 0.866025403784, 0, -0.866025403784, -0.5, 0, 0.5, 0.866025403784, 0, -0.866025403784, 0.5, 0 ];
const ac_24 = [
	1, 0, 0, 0, 1, 0, -0.5, -0.866025403784, 1.5, 0.866025403784, -0.5, -0.866025403784, -0.5, 0.866025403784, 1.5, -0.866025403784, -0.5, 0.866025403784, 0.5, 0.866025403784, 0, 0.866025403784, -0.5, 0, 0.5, -0.866025403784, 0, -0.866025403784, -0.5, 1.73205080757, -1, 0, 1.5, 0, 1, 0.866025403784 ];
const ac_25 = [
	1, 0, 0, 0, 1, 0, -0.5, 0.866025403784, 0.75, -0.866025403784, -0.5, 0.433012701892, -0.5, -0.866025403784, 0.75, 0.866025403784, -0.5, -0.433012701892 ];
const ac_26 = [
	1, 0, 0, 0, 1, 0, 0.5, -0.866025403784, 0.75, 0.866025403784, 0.5, 0.433012701892, -0.5, -0.866025403784, 0.75, 0.866025403784, -0.5, 1.29903810568 ];
const ac_27 = [
	1, 0, 0, 0, 1, 0, 0.5, 0.866025403784, 0.75, 0.866025403784, -0.5, 0.433012701892, -0.5, -0.866025403784, 0.75, 0.866025403784, -0.5, -0.433012701892 ];
const ac_28 = [
	1, 0, 0, 0, 1, 0, -0.5, -0.866025403784, 0.75, -0.866025403784, 0.5, 0.433012701892, -0.5, 0.866025403784, 0.75, 0.866025403784, 0.5, -0.433012701892 ];
const ac_29 = [
	1, 0, 0, 0, 1, 0, -0.5, 0.866025403784, -0.5, -0.866025403784, -0.5, 0.866025403784, -0.5, -0.866025403784, 0.5, 0.866025403784, -0.5, 0.866025403784, -0.5, 0.866025403784, -1.5, 0.866025403784, 0.5, 0.866025403784, -0.5, -0.866025403784, -0.5, -0.866025403784, 0.5, 0.866025403784, 1, 0, -1, 0, -1, 1.73205080757 ];
const ac_30 = [
	1, 0, 0, 0, 1, 0, -0.5, 0.866025403784, -0.5, -0.866025403784, -0.5, 0.866025403784, -0.5, -0.866025403784, 0.5, 0.866025403784, -0.5, 0.866025403784, 0.5, -0.866025403784, -0.5, 0.866025403784, 0.5, 0.866025403784, 0.5, 0.866025403784, -1.5, -0.866025403784, 0.5, 0.866025403784, -1, 0, -1, 0, -1, 1.73205080757 ];
const ac_31 = [
	1, 0, 0, 0, 1, 0, -0.5, -0.866025403784, 0.5, 0.866025403784, -0.5, 0.866025403784, -0.5, 0.866025403784, -0.5, -0.866025403784, -0.5, 0.866025403784, 0.5, 0.866025403784, 0.5, -0.866025403784, 0.5, 0.866025403784, 0.5, -0.866025403784, 1.5, 0.866025403784, 0.5, 0.866025403784, -1, 0, 1, 0, -1, 1.73205080757 ];
const ac_32 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 7.8, 0, 0.2, 0, 0, 0, 0, 0, 1, 0, 0, 0 ];
const ac_33 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 3.9, 0, 0.1, 0, 0, 0, 0, 0, -1, 0, 5, -1.5 ];
const ac_34 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 5, 0, -1, 0, 0, 0, 0, 0, 1, 0, -3.9, -0.1 ];
const ac_35 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, -3.45, 4, 0, 0, 0, 0, 0, -1, 3.9, 0, 0.1 ];
const ac_36 = [
	0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0 ];
const ac_37 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 7.8, 0, 0.2, 0, 0, 0, 0, 0, -1, 0, 10, -4 ];
const ac_38 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, -5, 3.9, 3.6, 0, 0, 0, 0, 0, 0, 0, -1, 3.9, 0, 0, 0.1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, -5, 3.9, 3.6, 0, 0, 0, 0, 0, 0, 0, 1, 3.9, 0, 0, 0.1 ];
const ac_39 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 1, 0, 0, -1, 0, 0, 0, 7.8, 0, 0.2, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, -7.8, 0, -0.2, 0, 0, 0, 0, 0, -1, 0, 0, 1 ];
const ac_40 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 10, 0, -5, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 10, -5, 0, 0, 0, -1, 0, 0, 0, 0, 0, 10, 0, -4, 0, 0, 0, 0, 0, 0, 0, 1, -10, 0, -10, 10, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, -1, -10, 0, 0, 5 ];
const ac_41 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0, 0, 2, 0, 0, 0, -1, 0, 0, 0, -1, 0, 0, 0, 1, 0, 0, 0, 1, -3.9, -0.1, 0, 1, 0, 0, 0, 1, 0, 0, 0, -1, 3.9, 0.1 ];
const ac_42 = [
	0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 5, 0, 0, -2, 0, 0, 0, 0, 0, 0, 0, -1, 0, -3.9, 0, -0.1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0, 3.9, 0.1, 0, 0, 0, -1, 0, 0, 0, 0, 5, 0, 0, -2.5, 0, 0, 0, 0, 0, 0, 0, 1, 0, -3.9, -3.9, -0.2 ];
const ac_43 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 3.9, 0, 0.1, 0, 0, 0, 0, 0, -1, 0, 3.9, 1.1, 0, 0, -1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, -3.9, 0, -0.1, 0, 0, 0, 0, 0, -1, 0, 3.9, 1.1 ];
const ac_44 = [
	1, 0, 0, 0, 1, 0, 0, 1, 0, -1, 0, 2, -1, 0, 2, 0, -1, 2, 0, -1, 2, 1, 0, 0 ];
const ac_45 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0, 0, 2, 0, -1, 0, 0, 0, 2, 0, 0, 0, -1, 0, 2, 0, 0, 0, -1, 0, 2, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 4, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 2, 0, -1, 0, 0, 0, 2, 0, 0, 0, 1, 0, 2, 0, 0, 0, -1, 0, 2, 0, -1, 0, 0, 0, 4 ];
const ac_46 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 3.8, 0.1, 0, 0, 0, -1, -3.8, 0.9 ];
const ac_47 = [
	1, 0, 0, 0, 1, 0, 0, -1, 2, 1, 0, 0 ];
const ac_48 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0 ];
const ac_49 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0, 0, 2, 0, 0, 0, -1, 3.9, 0.1 ];
const ac_50 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, -3.45, 5, 0, 0, 0, 0, 0, -1, 3.9, 0, 0.1 ];
const ac_51 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 5, 0, -1, 0, 0, 0, 0, 0, -1, 0, -3.9, -0.1 ];
const ac_52 = [
	1, 0, 0, 0, 1, 0, 0, -1, 2, 1, 0, 0, -1, 0, 2, 0, -1, 2, 0, 1, 0, -1, 0, 2 ];
const ac_53 = [
	1, 0, 0, 0, 1, 0, 0.5, 0.866025403784, -0.866025403784, -0.866025403784, 0.5, 0.5, -0.5, 0.866025403784, -0.866025403784, -0.866025403784, -0.5, 1.5, -1, 0, 0, 0, -1, 2, -0.5, -0.866025403784, 0.866025403784, 0.866025403784, -0.5, 1.5, 0.5, -0.866025403784, 0.866025403784, 0.866025403784, 0.5, 0.5, -1, 0, 0, 0, 1, 0, -0.5, 0.866025403784, -0.866025403784, 0.866025403784, 0.5, 0.5, 0.5, 0.866025403784, -0.866025403784, 0.866025403784, -0.5, 1.5, 1, 0, 0, 0, -1, 2, 0.5, -0.866025403784, 0.866025403784, -0.866025403784, -0.5, 1.5, -0.5, -0.866025403784, 0.866025403784, -0.866025403784, 0.5, 0.5 ];
const ac_54 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0, 0, 1, 0, 0, 0, -1, 3.9, 0.1, 0, -1, 0, 0, 0, 2, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, -1, 3.9, 0.1 ];
const ac_55 = [
	1, 0, 0, 0, 1, 0, 0, 1, 0, -1, 0, 1, -1, 0, 1, 0, -1, 1, 0, -1, 1, 1, 0, 0 ];
const ac_56 = [
	1, 0, 0, 0, 1, 0, 0, 1, 0, -1, 0, 1, -1, 0, 1, 0, -1, 1, 0, -1, 1, 1, 0, 0, -1, 0, 0, 0, 1, 0, 0, -1, 0, -1, 0, 1, 1, 0, -1, 0, -1, 1, 0, 1, -1, 1, 0, 0 ];
const ac_57 = [
	1, 0, 0, 0, 1, 0, 0, -1, 1, 1, 0, 0, -1, 0, 1, 0, -1, 1, 0, 1, 0, -1, 0, 1 ];
const ac_58 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, -1, 0, 0 ];
const ac_59 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0 ];
const ac_60 = [
	0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, -1, 0, 0, 0, 5, 0, -1, 0, 0, 0, 0, 0, -1, 0, 3.9, 0.1, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, -1, 0, 7.8, 0.2, 0, 0, -1, 0, 0, 0, 5, 0, -1, 0, 0, 0, 0, 0, 1, 0, 3.9, 0.1 ];
const ac_61 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0, 0, 1, 0, 0, 0, -1, 7.8, 0.2, 0, 1, 0, 0, 0, 0.5, 0, 0, 0, -1, 3.9, 0.1, 0, -1, 0, 0, 0, 1.5, 0, 0, 0, 1, 3.9, 0.1 ];
const ac_62 = [
	1, 0, 0, 0, 1, 0, 0.5, -0.866025403784, 0.5, 0.866025403784, 0.5, 0.866025403784, -0.5, -0.866025403784, 0, 0.866025403784, -0.5, 1.73205080757, -1, 0, -1, 0, -1, 1.73205080757, -0.5, 0.866025403784, -1.5, -0.866025403784, -0.5, 0.866025403784, 0.5, 0.866025403784, -1, -0.866025403784, 0.5, 0 ];
const ac_63 = [
	1, 0, 0, 0, 1, 0, -1, 0, 0.5, 0, -1, 0.866025403784 ];
const ac_64 = [
	0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0, 0, 1, 0, 0, 0, -1, 0, 0 ];

const c_00 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 2, 0, 1, 3 ];
const c_01 = [ 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 1, 2, 3 ];
const c_02 = [ 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 2, 0, 1, 3 ];
const c_03 = [ 0, 1, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 2, 0, 1, 3 ];
const c_04 = [ 0, 1, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 1, 2, 3 ];
const c_05 = [ 0, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 1, 2, 3 ];
const c_06 = [ 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 0, 1, 2, 3 ];
const c_07 = [ 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 2, 0, 2, 0, 1, 3 ];
const c_08 = [ 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 2, 0, 1, 3 ];
const c_09 = [ 0, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 1, 2, 0, 3 ];
const c_10 = [ 0, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 1, 0, 1, 2, 3 ];
const c_11 = [ 0, 1, 2, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 1, 2, 0, 3 ];
const c_12 = [ 0, 1, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 0, 1, 2, 3 ];
const c_13 = [ 0, 1, 2, 1, 2, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 2, 0, 1, 3 ];
const c_14 = [ 0, 1, 2, 0, 1, 2, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 1, 2, 3 ];
const c_15 = [ 0, 2, 1, 1, 0, 2, 0, 0, 0, 0, 0, 0, 1, 2, 0, 2, 0, 1, 3 ];
const c_16 = [ 0, 2, 1, 0, 1, 2, 0, 0, 0, 0, 0, 0, 2, 0, 1, 1, 2, 0, 3 ];
const c_17 = [ 1, 0, 2, 2, 0, 1, 0, 0, 0, 0, 0, 0, 1, 2, 0, 2, 0, 1, 3 ];
const c_18 = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 1, 0, 2, 2 ];
const c_19 = [ 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 0, 1, 2, 2 ];
const c_20 = [ 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 1, 2, 2 ];
const c_21 = [ 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 1, 2, 2 ];
const c_22 = [ 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 1, 0, 2, 2 ];
const c_23 = [ 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 1, 2, 2 ];
const c_24 = [ 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 1, 2, 2 ];
const c_25 = [ 0, 1, 0, 1, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 2, 0, 1, 2, 2 ];
const c_26 = [ 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 1, 2, 0, 1, 2, 2 ];
const c_27 = [ 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 1, 0, 2, 2 ];
const c_28 = [ 0, 1, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 2, 0, 1, 2, 2 ];

return [
	// IH00 is undefined
	null,

	// IH01
	{
		num_params: 4,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 3,
		edge_shapes: es_00,
		edge_orientations: eo_00,
		edge_shape_ids: esi_00,
		default_params: dp_00,
		vertex_coeffs: tvc_00,
		translation_coeffs: tc_00,
		aspect_coeffs: ac_00,
		colouring: c_00
	},

	// IH02
	{
		num_params: 4,
		num_aspects: 2,
		num_vertices: 6,
		num_edge_shapes: 3,
		edge_shapes: es_00,
		edge_orientations: eo_01,
		edge_shape_ids: esi_01,
		default_params: dp_01,
		vertex_coeffs: tvc_01,
		translation_coeffs: tc_01,
		aspect_coeffs: ac_01,
		colouring: c_01
	},

	// IH03
	{
		num_params: 4,
		num_aspects: 2,
		num_vertices: 6,
		num_edge_shapes: 3,
		edge_shapes: es_00,
		edge_orientations: eo_02,
		edge_shape_ids: esi_02,
		default_params: dp_02,
		vertex_coeffs: tvc_02,
		translation_coeffs: tc_02,
		aspect_coeffs: ac_02,
		colouring: c_02
	},

	// IH04
	{
		num_params: 6,
		num_aspects: 2,
		num_vertices: 6,
		num_edge_shapes: 5,
		edge_shapes: es_01,
		edge_orientations: eo_03,
		edge_shape_ids: esi_03,
		default_params: dp_03,
		vertex_coeffs: tvc_03,
		translation_coeffs: tc_03,
		aspect_coeffs: ac_03,
		colouring: c_02
	},

	// IH05
	{
		num_params: 5,
		num_aspects: 4,
		num_vertices: 6,
		num_edge_shapes: 4,
		edge_shapes: es_02,
		edge_orientations: eo_04,
		edge_shape_ids: esi_04,
		default_params: dp_04,
		vertex_coeffs: tvc_04,
		translation_coeffs: tc_04,
		aspect_coeffs: ac_04,
		colouring: c_03
	},

	// IH06
	{
		num_params: 5,
		num_aspects: 4,
		num_vertices: 6,
		num_edge_shapes: 4,
		edge_shapes: es_03,
		edge_orientations: eo_05,
		edge_shape_ids: esi_05,
		default_params: dp_05,
		vertex_coeffs: tvc_05,
		translation_coeffs: tc_05,
		aspect_coeffs: ac_05,
		colouring: c_04
	},

	// IH07
	{
		num_params: 2,
		num_aspects: 3,
		num_vertices: 6,
		num_edge_shapes: 3,
		edge_shapes: es_00,
		edge_orientations: eo_06,
		edge_shape_ids: esi_06,
		default_params: dp_06,
		vertex_coeffs: tvc_06,
		translation_coeffs: tc_06,
		aspect_coeffs: ac_06,
		colouring: c_05
	},

	// IH08
	{
		num_params: 4,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 3,
		edge_shapes: es_04,
		edge_orientations: eo_07,
		edge_shape_ids: esi_00,
		default_params: dp_00,
		vertex_coeffs: tvc_00,
		translation_coeffs: tc_00,
		aspect_coeffs: ac_00,
		colouring: c_00
	},

	// IH09
	{
		num_params: 3,
		num_aspects: 2,
		num_vertices: 6,
		num_edge_shapes: 2,
		edge_shapes: es_05,
		edge_orientations: eo_08,
		edge_shape_ids: esi_07,
		default_params: dp_07,
		vertex_coeffs: tvc_07,
		translation_coeffs: tc_07,
		aspect_coeffs: ac_07,
		colouring: c_06
	},

	// IH10
	{
		num_params: 0,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 1,
		edge_shapes: es_06,
		edge_orientations: eo_06,
		edge_shape_ids: esi_08,
		default_params: dp_08,
		vertex_coeffs: tvc_08,
		translation_coeffs: tc_08,
		aspect_coeffs: ac_08,
		colouring: c_00
	},

	// IH11
	{
		num_params: 0,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 1,
		edge_shapes: es_07,
		edge_orientations: eo_07,
		edge_shape_ids: esi_08,
		default_params: dp_08,
		vertex_coeffs: tvc_08,
		translation_coeffs: tc_09,
		aspect_coeffs: ac_08,
		colouring: c_00
	},

	// IH12
	{
		num_params: 2,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 2,
		edge_shapes: es_08,
		edge_orientations: eo_09,
		edge_shape_ids: esi_07,
		default_params: dp_09,
		vertex_coeffs: tvc_09,
		translation_coeffs: tc_10,
		aspect_coeffs: ac_09,
		colouring: c_00
	},

	// IH13
	{
		num_params: 3,
		num_aspects: 2,
		num_vertices: 6,
		num_edge_shapes: 3,
		edge_shapes: es_09,
		edge_orientations: eo_10,
		edge_shape_ids: esi_09,
		default_params: dp_10,
		vertex_coeffs: tvc_10,
		translation_coeffs: tc_11,
		aspect_coeffs: ac_10,
		colouring: c_06
	},

	// IH14
	{
		num_params: 2,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 2,
		edge_shapes: es_10,
		edge_orientations: eo_11,
		edge_shape_ids: esi_10,
		default_params: dp_09,
		vertex_coeffs: tvc_11,
		translation_coeffs: tc_12,
		aspect_coeffs: ac_09,
		colouring: c_00
	},

	// IH15
	{
		num_params: 3,
		num_aspects: 2,
		num_vertices: 6,
		num_edge_shapes: 3,
		edge_shapes: es_11,
		edge_orientations: eo_12,
		edge_shape_ids: esi_11,
		default_params: dp_11,
		vertex_coeffs: tvc_12,
		translation_coeffs: tc_13,
		aspect_coeffs: ac_11,
		colouring: c_06
	},

	// IH16
	{
		num_params: 1,
		num_aspects: 3,
		num_vertices: 6,
		num_edge_shapes: 2,
		edge_shapes: es_12,
		edge_orientations: eo_13,
		edge_shape_ids: esi_12,
		default_params: dp_12,
		vertex_coeffs: tvc_13,
		translation_coeffs: tc_14,
		aspect_coeffs: ac_12,
		colouring: c_05
	},

	// IH17
	{
		num_params: 2,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 2,
		edge_shapes: es_13,
		edge_orientations: eo_14,
		edge_shape_ids: esi_07,
		default_params: dp_09,
		vertex_coeffs: tvc_09,
		translation_coeffs: tc_10,
		aspect_coeffs: ac_09,
		colouring: c_00
	},

	// IH18
	{
		num_params: 0,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 1,
		edge_shapes: es_14,
		edge_orientations: eo_06,
		edge_shape_ids: esi_08,
		default_params: dp_08,
		vertex_coeffs: tvc_08,
		translation_coeffs: tc_09,
		aspect_coeffs: ac_08,
		colouring: c_00
	},

	// IH19 is undefined
	null,

	// IH20
	{
		num_params: 0,
		num_aspects: 1,
		num_vertices: 6,
		num_edge_shapes: 1,
		edge_shapes: es_15,
		edge_orientations: eo_07,
		edge_shape_ids: esi_08,
		default_params: dp_08,
		vertex_coeffs: tvc_08,
		translation_coeffs: tc_09,
		aspect_coeffs: ac_08,
		colouring: c_00
	},

	// IH21
	{
		num_params: 2,
		num_aspects: 6,
		num_vertices: 5,
		num_edge_shapes: 3,
		edge_shapes: es_16,
		edge_orientations: eo_15,
		edge_shape_ids: esi_13,
		default_params: dp_13,
		vertex_coeffs: tvc_14,
		translation_coeffs: tc_15,
		aspect_coeffs: ac_13,
		colouring: c_07
	},

	// IH22
	{
		num_params: 3,
		num_aspects: 2,
		num_vertices: 5,
		num_edge_shapes: 3,
		edge_shapes: es_17,
		edge_orientations: eo_16,
		edge_shape_ids: esi_14,
		default_params: dp_14,
		vertex_coeffs: tvc_15,
		translation_coeffs: tc_16,
		aspect_coeffs: ac_14,
		colouring: c_06
	},

	// IH23
	{
		num_params: 4,
		num_aspects: 2,
		num_vertices: 5,
		num_edge_shapes: 4,
		edge_shapes: es_18,
		edge_orientations: eo_17,
		edge_shape_ids: esi_15,
		default_params: dp_15,
		vertex_coeffs: tvc_16,
		translation_coeffs: tc_17,
		aspect_coeffs: ac_15,
		colouring: c_08
	},

	// IH24
	{
		num_params: 4,
		num_aspects: 4,
		num_vertices: 5,
		num_edge_shapes: 4,
		edge_shapes: es_19,
		edge_orientations: eo_17,
		edge_shape_ids: esi_15,
		default_params: dp_15,
		vertex_coeffs: tvc_16,
		translation_coeffs: tc_18,
		aspect_coeffs: ac_16,
		colouring: c_09
	},

	// IH25
	{
		num_params: 3,
		num_aspects: 4,
		num_vertices: 5,
		num_edge_shapes: 3,
		edge_shapes: es_20,
		edge_orientations: eo_16,
		edge_shape_ids: esi_14,
		default_params: dp_14,
		vertex_coeffs: tvc_15,
		translation_coeffs: tc_19,
		aspect_coeffs: ac_17,
		colouring: c_10
	},

	// IH26
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 5,
		num_edge_shapes: 3,
		edge_shapes: es_21,
		edge_orientations: eo_18,
		edge_shape_ids: esi_14,
		default_params: dp_16,
		vertex_coeffs: tvc_17,
		translation_coeffs: tc_20,
		aspect_coeffs: ac_18,
		colouring: c_01
	},

	// IH27
	{
		num_params: 3,
		num_aspects: 4,
		num_vertices: 5,
		num_edge_shapes: 3,
		edge_shapes: es_16,
		edge_orientations: eo_19,
		edge_shape_ids: esi_16,
		default_params: dp_17,
		vertex_coeffs: tvc_18,
		translation_coeffs: tc_21,
		aspect_coeffs: ac_19,
		colouring: c_11
	},

	// IH28
	{
		num_params: 2,
		num_aspects: 4,
		num_vertices: 5,
		num_edge_shapes: 3,
		edge_shapes: es_16,
		edge_orientations: eo_15,
		edge_shape_ids: esi_13,
		default_params: dp_18,
		vertex_coeffs: tvc_19,
		translation_coeffs: tc_22,
		aspect_coeffs: ac_20,
		colouring: c_12
	},

	// IH29
	{
		num_params: 1,
		num_aspects: 4,
		num_vertices: 5,
		num_edge_shapes: 2,
		edge_shapes: es_12,
		edge_orientations: eo_20,
		edge_shape_ids: esi_17,
		default_params: dp_19,
		vertex_coeffs: tvc_20,
		translation_coeffs: tc_23,
		aspect_coeffs: ac_21,
		colouring: c_04
	},

	// IH30
	{
		num_params: 1,
		num_aspects: 6,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_22,
		edge_orientations: eo_21,
		edge_shape_ids: esi_18,
		default_params: dp_20,
		vertex_coeffs: tvc_21,
		translation_coeffs: tc_24,
		aspect_coeffs: ac_22,
		colouring: c_13
	},

	// IH31
	{
		num_params: 0,
		num_aspects: 6,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_23,
		edge_orientations: eo_22,
		edge_shape_ids: esi_19,
		default_params: dp_08,
		vertex_coeffs: tvc_22,
		translation_coeffs: tc_25,
		aspect_coeffs: ac_23,
		colouring: c_14
	},

	// IH32
	{
		num_params: 0,
		num_aspects: 6,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_24,
		edge_orientations: eo_23,
		edge_shape_ids: esi_19,
		default_params: dp_08,
		vertex_coeffs: tvc_22,
		translation_coeffs: tc_26,
		aspect_coeffs: ac_24,
		colouring: c_15
	},

	// IH33
	{
		num_params: 0,
		num_aspects: 3,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_23,
		edge_orientations: eo_22,
		edge_shape_ids: esi_19,
		default_params: dp_08,
		vertex_coeffs: tvc_23,
		translation_coeffs: tc_08,
		aspect_coeffs: ac_25,
		colouring: c_05
	},

	// IH34
	{
		num_params: 0,
		num_aspects: 3,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_06,
		edge_orientations: eo_24,
		edge_shape_ids: esi_20,
		default_params: dp_08,
		vertex_coeffs: tvc_23,
		translation_coeffs: tc_09,
		aspect_coeffs: ac_26,
		colouring: c_05
	},

	// IH35 is undefined
	null,

	// IH36
	{
		num_params: 0,
		num_aspects: 3,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_06,
		edge_orientations: eo_25,
		edge_shape_ids: esi_20,
		default_params: dp_08,
		vertex_coeffs: tvc_23,
		translation_coeffs: tc_08,
		aspect_coeffs: ac_27,
		colouring: c_05
	},

	// IH37
	{
		num_params: 0,
		num_aspects: 3,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_15,
		edge_orientations: eo_26,
		edge_shape_ids: esi_20,
		default_params: dp_08,
		vertex_coeffs: tvc_23,
		translation_coeffs: tc_08,
		aspect_coeffs: ac_28,
		colouring: c_05
	},

	// IH38
	{
		num_params: 0,
		num_aspects: 6,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_10,
		edge_orientations: eo_27,
		edge_shape_ids: esi_21,
		default_params: dp_08,
		vertex_coeffs: tvc_24,
		translation_coeffs: tc_27,
		aspect_coeffs: ac_29,
		colouring: c_15
	},

	// IH39
	{
		num_params: 0,
		num_aspects: 6,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_25,
		edge_orientations: eo_27,
		edge_shape_ids: esi_21,
		default_params: dp_08,
		vertex_coeffs: tvc_24,
		translation_coeffs: tc_28,
		aspect_coeffs: ac_30,
		colouring: c_16
	},

	// IH40
	{
		num_params: 0,
		num_aspects: 6,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_24,
		edge_orientations: eo_28,
		edge_shape_ids: esi_21,
		default_params: dp_08,
		vertex_coeffs: tvc_24,
		translation_coeffs: tc_29,
		aspect_coeffs: ac_31,
		colouring: c_17
	},

	// IH41
	{
		num_params: 2,
		num_aspects: 1,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_23,
		edge_orientations: eo_22,
		edge_shape_ids: esi_22,
		default_params: dp_21,
		vertex_coeffs: tvc_25,
		translation_coeffs: tc_30,
		aspect_coeffs: ac_09,
		colouring: c_18
	},

	// IH42
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_22,
		edge_orientations: eo_29,
		edge_shape_ids: esi_23,
		default_params: dp_21,
		vertex_coeffs: tvc_25,
		translation_coeffs: tc_31,
		aspect_coeffs: ac_32,
		colouring: c_19
	},

	// IH43
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_23,
		edge_orientations: eo_30,
		edge_shape_ids: esi_22,
		default_params: dp_21,
		vertex_coeffs: tvc_25,
		translation_coeffs: tc_31,
		aspect_coeffs: ac_33,
		colouring: c_19
	},

	// IH44
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_23,
		edge_orientations: eo_31,
		edge_shape_ids: esi_24,
		default_params: dp_22,
		vertex_coeffs: tvc_26,
		translation_coeffs: tc_32,
		aspect_coeffs: ac_34,
		colouring: c_20
	},

	// IH45
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_22,
		edge_orientations: eo_32,
		edge_shape_ids: esi_23,
		default_params: dp_23,
		vertex_coeffs: tvc_27,
		translation_coeffs: tc_33,
		aspect_coeffs: ac_35,
		colouring: c_20
	},

	// IH46
	{
		num_params: 4,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 4,
		edge_shapes: es_26,
		edge_orientations: eo_33,
		edge_shape_ids: esi_25,
		default_params: dp_24,
		vertex_coeffs: tvc_28,
		translation_coeffs: tc_34,
		aspect_coeffs: ac_36,
		colouring: c_20
	},

	// IH47
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_27,
		edge_orientations: eo_29,
		edge_shape_ids: esi_23,
		default_params: dp_21,
		vertex_coeffs: tvc_25,
		translation_coeffs: tc_35,
		aspect_coeffs: ac_37,
		colouring: c_19
	},

	// IH48 is undefined
	null,

	// IH49
	{
		num_params: 3,
		num_aspects: 4,
		num_vertices: 4,
		num_edge_shapes: 4,
		edge_shapes: es_28,
		edge_orientations: eo_33,
		edge_shape_ids: esi_25,
		default_params: dp_25,
		vertex_coeffs: tvc_29,
		translation_coeffs: tc_36,
		aspect_coeffs: ac_38,
		colouring: c_21
	},

	// IH50
	{
		num_params: 2,
		num_aspects: 4,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_29,
		edge_orientations: eo_29,
		edge_shape_ids: esi_23,
		default_params: dp_21,
		vertex_coeffs: tvc_25,
		translation_coeffs: tc_37,
		aspect_coeffs: ac_39,
		colouring: c_22
	},

	// IH51
	{
		num_params: 3,
		num_aspects: 4,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_27,
		edge_orientations: eo_32,
		edge_shape_ids: esi_23,
		default_params: dp_26,
		vertex_coeffs: tvc_30,
		translation_coeffs: tc_38,
		aspect_coeffs: ac_40,
		colouring: c_21
	},

	// IH52
	{
		num_params: 1,
		num_aspects: 4,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_23,
		edge_orientations: eo_34,
		edge_shape_ids: esi_22,
		default_params: dp_20,
		vertex_coeffs: tvc_31,
		translation_coeffs: tc_39,
		aspect_coeffs: ac_41,
		colouring: c_23
	},

	// IH53
	{
		num_params: 3,
		num_aspects: 4,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_27,
		edge_orientations: eo_35,
		edge_shape_ids: esi_26,
		default_params: dp_27,
		vertex_coeffs: tvc_32,
		translation_coeffs: tc_40,
		aspect_coeffs: ac_42,
		colouring: c_21
	},

	// IH54
	{
		num_params: 2,
		num_aspects: 4,
		num_vertices: 4,
		num_edge_shapes: 4,
		edge_shapes: es_30,
		edge_orientations: eo_33,
		edge_shape_ids: esi_25,
		default_params: dp_28,
		vertex_coeffs: tvc_33,
		translation_coeffs: tc_41,
		aspect_coeffs: ac_43,
		colouring: c_22
	},

	// IH55
	{
		num_params: 0,
		num_aspects: 4,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_23,
		edge_orientations: eo_24,
		edge_shape_ids: esi_24,
		default_params: dp_08,
		vertex_coeffs: tvc_34,
		translation_coeffs: tc_42,
		aspect_coeffs: ac_44,
		colouring: c_24
	},

	// IH56
	{
		num_params: 1,
		num_aspects: 8,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_22,
		edge_orientations: eo_36,
		edge_shape_ids: esi_26,
		default_params: dp_29,
		vertex_coeffs: tvc_35,
		translation_coeffs: tc_43,
		aspect_coeffs: ac_45,
		colouring: c_25
	},

	// IH57
	{
		num_params: 2,
		num_aspects: 1,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_31,
		edge_orientations: eo_33,
		edge_shape_ids: esi_22,
		default_params: dp_21,
		vertex_coeffs: tvc_25,
		translation_coeffs: tc_30,
		aspect_coeffs: ac_09,
		colouring: c_18
	},

	// IH58
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_32,
		edge_orientations: eo_33,
		edge_shape_ids: esi_22,
		default_params: dp_21,
		vertex_coeffs: tvc_25,
		translation_coeffs: tc_31,
		aspect_coeffs: ac_32,
		colouring: c_19
	},

	// IH59
	{
		num_params: 1,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_06,
		edge_orientations: eo_31,
		edge_shape_ids: esi_20,
		default_params: dp_30,
		vertex_coeffs: tvc_36,
		translation_coeffs: tc_44,
		aspect_coeffs: ac_46,
		colouring: c_20
	},

	// IH60 is undefined
	null,

	// IH61
	{
		num_params: 0,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_06,
		edge_orientations: eo_24,
		edge_shape_ids: esi_20,
		default_params: dp_08,
		vertex_coeffs: tvc_34,
		translation_coeffs: tc_45,
		aspect_coeffs: ac_47,
		colouring: c_20
	},

	// IH62
	{
		num_params: 0,
		num_aspects: 1,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_07,
		edge_orientations: eo_33,
		edge_shape_ids: esi_20,
		default_params: dp_08,
		vertex_coeffs: tvc_34,
		translation_coeffs: tc_46,
		aspect_coeffs: ac_08,
		colouring: c_18
	},

	// IH63 is undefined
	null,

	// IH64
	{
		num_params: 1,
		num_aspects: 1,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_33,
		edge_orientations: eo_37,
		edge_shape_ids: esi_22,
		default_params: dp_20,
		vertex_coeffs: tvc_31,
		translation_coeffs: tc_47,
		aspect_coeffs: ac_48,
		colouring: c_18
	},

	// IH65 is undefined
	null,

	// IH66
	{
		num_params: 1,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_34,
		edge_orientations: eo_37,
		edge_shape_ids: esi_22,
		default_params: dp_20,
		vertex_coeffs: tvc_31,
		translation_coeffs: tc_48,
		aspect_coeffs: ac_49,
		colouring: c_19
	},

	// IH67
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 3,
		edge_shapes: es_21,
		edge_orientations: eo_38,
		edge_shape_ids: esi_23,
		default_params: dp_23,
		vertex_coeffs: tvc_27,
		translation_coeffs: tc_49,
		aspect_coeffs: ac_50,
		colouring: c_20
	},

	// IH68
	{
		num_params: 1,
		num_aspects: 1,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_06,
		edge_orientations: eo_39,
		edge_shape_ids: esi_20,
		default_params: dp_30,
		vertex_coeffs: tvc_36,
		translation_coeffs: tc_50,
		aspect_coeffs: ac_48,
		colouring: c_18
	},

	// IH69
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_31,
		edge_orientations: eo_26,
		edge_shape_ids: esi_24,
		default_params: dp_22,
		vertex_coeffs: tvc_26,
		translation_coeffs: tc_32,
		aspect_coeffs: ac_51,
		colouring: c_20
	},

	// IH70 is undefined
	null,

	// IH71
	{
		num_params: 0,
		num_aspects: 4,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_06,
		edge_orientations: eo_40,
		edge_shape_ids: esi_20,
		default_params: dp_08,
		vertex_coeffs: tvc_34,
		translation_coeffs: tc_42,
		aspect_coeffs: ac_52,
		colouring: c_24
	},

	// IH72
	{
		num_params: 1,
		num_aspects: 1,
		num_vertices: 4,
		num_edge_shapes: 2,
		edge_shapes: es_24,
		edge_orientations: eo_33,
		edge_shape_ids: esi_22,
		default_params: dp_20,
		vertex_coeffs: tvc_31,
		translation_coeffs: tc_47,
		aspect_coeffs: ac_48,
		colouring: c_18
	},

	// IH73
	{
		num_params: 0,
		num_aspects: 2,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_14,
		edge_orientations: eo_24,
		edge_shape_ids: esi_20,
		default_params: dp_08,
		vertex_coeffs: tvc_34,
		translation_coeffs: tc_45,
		aspect_coeffs: ac_47,
		colouring: c_20
	},

	// IH74
	{
		num_params: 1,
		num_aspects: 1,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_07,
		edge_orientations: eo_26,
		edge_shape_ids: esi_20,
		default_params: dp_30,
		vertex_coeffs: tvc_36,
		translation_coeffs: tc_50,
		aspect_coeffs: ac_48,
		colouring: c_18
	},

	// IH75 is undefined
	null,

	// IH76
	{
		num_params: 0,
		num_aspects: 1,
		num_vertices: 4,
		num_edge_shapes: 1,
		edge_shapes: es_15,
		edge_orientations: eo_33,
		edge_shape_ids: esi_20,
		default_params: dp_08,
		vertex_coeffs: tvc_34,
		translation_coeffs: tc_46,
		aspect_coeffs: ac_08,
		colouring: c_18
	},

	// IH77
	{
		num_params: 0,
		num_aspects: 12,
		num_vertices: 3,
		num_edge_shapes: 3,
		edge_shapes: es_35,
		edge_orientations: eo_41,
		edge_shape_ids: esi_27,
		default_params: dp_08,
		vertex_coeffs: tvc_37,
		translation_coeffs: tc_51,
		aspect_coeffs: ac_53,
		colouring: c_26
	},

	// IH78
	{
		num_params: 1,
		num_aspects: 4,
		num_vertices: 3,
		num_edge_shapes: 3,
		edge_shapes: es_36,
		edge_orientations: eo_41,
		edge_shape_ids: esi_27,
		default_params: dp_20,
		vertex_coeffs: tvc_38,
		translation_coeffs: tc_52,
		aspect_coeffs: ac_54,
		colouring: c_22
	},

	// IH79
	{
		num_params: 0,
		num_aspects: 4,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_25,
		edge_orientations: eo_27,
		edge_shape_ids: esi_21,
		default_params: dp_08,
		vertex_coeffs: tvc_39,
		translation_coeffs: tc_53,
		aspect_coeffs: ac_55,
		colouring: c_27
	},

	// IH80 is undefined
	null,

	// IH81
	{
		num_params: 0,
		num_aspects: 8,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_10,
		edge_orientations: eo_27,
		edge_shape_ids: esi_21,
		default_params: dp_08,
		vertex_coeffs: tvc_39,
		translation_coeffs: tc_54,
		aspect_coeffs: ac_56,
		colouring: c_25
	},

	// IH82
	{
		num_params: 0,
		num_aspects: 4,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_24,
		edge_orientations: eo_28,
		edge_shape_ids: esi_21,
		default_params: dp_08,
		vertex_coeffs: tvc_39,
		translation_coeffs: tc_55,
		aspect_coeffs: ac_57,
		colouring: c_27
	},

	// IH83
	{
		num_params: 1,
		num_aspects: 2,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_10,
		edge_orientations: eo_42,
		edge_shape_ids: esi_28,
		default_params: dp_31,
		vertex_coeffs: tvc_40,
		translation_coeffs: tc_56,
		aspect_coeffs: ac_58,
		colouring: c_20
	},

	// IH84
	{
		num_params: 2,
		num_aspects: 2,
		num_vertices: 3,
		num_edge_shapes: 3,
		edge_shapes: es_04,
		edge_orientations: eo_41,
		edge_shape_ids: esi_27,
		default_params: dp_32,
		vertex_coeffs: tvc_41,
		translation_coeffs: tc_57,
		aspect_coeffs: ac_59,
		colouring: c_20
	},

	// IH85
	{
		num_params: 2,
		num_aspects: 4,
		num_vertices: 3,
		num_edge_shapes: 3,
		edge_shapes: es_37,
		edge_orientations: eo_41,
		edge_shape_ids: esi_27,
		default_params: dp_32,
		vertex_coeffs: tvc_41,
		translation_coeffs: tc_58,
		aspect_coeffs: ac_60,
		colouring: c_21
	},

	// IH86
	{
		num_params: 1,
		num_aspects: 4,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_25,
		edge_orientations: eo_42,
		edge_shape_ids: esi_28,
		default_params: dp_31,
		vertex_coeffs: tvc_40,
		translation_coeffs: tc_59,
		aspect_coeffs: ac_61,
		colouring: c_21
	},

	// IH87 is undefined
	null,

	// IH88
	{
		num_params: 0,
		num_aspects: 6,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_25,
		edge_orientations: eo_43,
		edge_shape_ids: esi_28,
		default_params: dp_08,
		vertex_coeffs: tvc_42,
		translation_coeffs: tc_60,
		aspect_coeffs: ac_62,
		colouring: c_28
	},

	// IH89 is undefined
	null,

	// IH90
	{
		num_params: 0,
		num_aspects: 2,
		num_vertices: 3,
		num_edge_shapes: 1,
		edge_shapes: es_07,
		edge_orientations: eo_41,
		edge_shape_ids: esi_29,
		default_params: dp_08,
		vertex_coeffs: tvc_42,
		translation_coeffs: tc_09,
		aspect_coeffs: ac_63,
		colouring: c_20
	},

	// IH91
	{
		num_params: 1,
		num_aspects: 2,
		num_vertices: 3,
		num_edge_shapes: 2,
		edge_shapes: es_32,
		edge_orientations: eo_44,
		edge_shape_ids: esi_28,
		default_params: dp_31,
		vertex_coeffs: tvc_40,
		translation_coeffs: tc_61,
		aspect_coeffs: ac_64,
		colouring: c_20
	},

	// IH92 is undefined
	null,

	// IH93
	{
		num_params: 0,
		num_aspects: 2,
		num_vertices: 3,
		num_edge_shapes: 1,
		edge_shapes: es_15,
		edge_orientations: eo_41,
		edge_shape_ids: esi_29,
		default_params: dp_08,
		vertex_coeffs: tvc_42,
		translation_coeffs: tc_09,
		aspect_coeffs: ac_63,
		colouring: c_20
	}

];})();
function makePoint( coeffs, offs, params )
{
	let ret = { x : 0.0, y : 0.0 }

	for( let i = 0; i < params.length; ++i ) {
		ret.x += coeffs[offs+i] * params[i];
		ret.y += coeffs[offs+params.length+i] * params[i];
	}

	return ret;
};

function makeMatrix( coeffs, offs, params )
{
	let ret = []

	for( let row = 0; row < 2; ++row ) {
		for( let col = 0; col < 3; ++col ) {
			let val = 0.0;
			for( let idx = 0; idx < params.length; ++idx ) {
				val += coeffs[offs+idx] * params[idx];
			}
			ret.push( val );
			offs += params.length;
		}
	}

	return ret;
};

const M_orients = [
	[1.0, 0.0, 0.0,    0.0, 1.0, 0.0],   // IDENTITY
	[-1.0, 0.0, 1.0,   0.0, -1.0, 0.0],  // ROT
	[-1.0, 0.0, 1.0,   0.0, 1.0, 0.0],   // FLIP
	[1.0, 0.0, 0.0,    0.0, -1.0, 0.0]   // ROFL
];

const TSPI_U = [
	[0.5, 0.0, 0.0,    0.0, 0.5, 0.0],
	[-0.5, 0.0, 1.0,   0.0, 0.5, 0.0]
];

const TSPI_S = [
	[0.5, 0.0, 0.0,    0.0, 0.5, 0.0],
	[-0.5, 0.0, 1.0,   0.0, -0.5, 0.0]
];

class IsohedralTiling
{
	constructor( tp )
	{
		this.reset( tp );
	}

	reset( tp )
	{
		this.tiling_type = tp;
		this.ttd = tiling_type_data[ tp ];
		this.parameters = this.ttd.default_params.slice( 0 );
		this.parameters.push( 1.0 );
		this.recompute();
	}

	recompute()
	{
		const ntv = this.numVertices();
		const np = this.numParameters();
		const na = this.numAspects();

		// Recompute tiling vertex locations.
		this.verts = [];
		for( let idx = 0; idx < ntv; ++idx ) {
			this.verts.push( makePoint( this.ttd.vertex_coeffs,
				idx * (2 * (np + 1)), this.parameters ) );
		}

		// Recompute edge transforms and reversals from orientation information.
		this.reversals = [];
		this.edges = []
		for( let idx = 0; idx < ntv; ++idx ) {
			const fl = this.ttd.edge_orientations[2*idx];
			const ro = this.ttd.edge_orientations[2*idx+1];
			this.reversals.push( fl != ro );
			this.edges.push( 
				mul( matchSeg( this.verts[idx], this.verts[(idx+1)%ntv] ),
				M_orients[2*fl+ro] ) );
		}

		// Recompute aspect xforms.
		this.aspects = []
		for( let idx = 0; idx < na; ++idx ) {
			this.aspects.push( 
				makeMatrix( this.ttd.aspect_coeffs, 6*(np+1)*idx,
					this.parameters ) );
		}
					
		// Recompute translation vectors.
		this.t1 = makePoint(
			this.ttd.translation_coeffs, 0, this.parameters );
		this.t2 = makePoint(
			this.ttd.translation_coeffs, 2*(np+1), this.parameters );
	}

	getTilingType()
	{
		return this.tiling_type;
	}

	numParameters()
	{
		return this.ttd.num_params;
	}

	setParameters( arr )
	{
		if( arr.length == (this.parameters.length-1) ) {
			this.parameters = arr.slice( 0 );
			this.parameters.push( 1.0 );
			this.recompute();
		}
	}

	getParameters()
	{
		return this.parameters.slice( 0, -1 );
	}

	numEdgeShapes()
	{
		return this.ttd.num_edge_shapes;
	}

	getEdgeShape( idx )
	{
		return this.ttd.edge_shapes[ idx ];
	}

	* shape()
	{
		for( let idx = 0; idx < this.numVertices(); ++idx ) {
			const an_id = this.ttd.edge_shape_ids[idx];

			yield {
				T : this.edges[idx],
				id : an_id,
				shape : this.ttd.edge_shapes[ an_id ],
				rev : this.reversals[ idx ]
			};
		}
	}

	* parts()
	{
		for( let idx = 0; idx < this.numVertices(); ++idx ) {
			const an_id = this.ttd.edge_shape_ids[idx];
			const shp = this.ttd.edge_shapes[an_id];

			if( (shp == EdgeShape.J) || (shp == EdgeShape.I) ) {
				yield {
					T : this.edges[idx],
					id : an_id,
					shape : shp,
					rev : this.reversals[ idx ],
					second : false
				};
			} else {
				const indices = this.reversals[idx] ? [1,0] : [0,1];
				const Ms = (shp == EdgeShape.U) ? TSPI_U : TSPI_S;

				yield {
					T : mul( this.edges[idx], Ms[indices[0]] ),
					id : an_id,
					shape : shp,
					rev : false,
					second : false
				};
				
				yield {
					T : mul( this.edges[idx], Ms[indices[1]] ),
					id : an_id,
					shape : shp,
					rev : true,
					second : true
				};
			}
		}
	}

	numVertices()
	{
		return this.ttd.num_vertices;
	}

	getVertex( idx )
	{
		return { ...this.verts[ idx ] };
	}

	vertices()
	{
		return this.verts.map( v => ({ ...v }) );
	}

	numAspects()
	{
		return this.ttd.num_aspects;
	}
	
	getAspectTransform( idx )
	{
		return [ ...this.aspects[ idx ] ];
	}

	getT1()
	{
		return { ...this.t1 };
	}

	getT2()
	{
		return { ...this.t2 };
	}

	* fillRegionBounds( xmin, ymin, xmax, ymax )
	{
		yield* this.fillRegionQuad(
			{ x : xmin, y : ymin },
			{ x : xmax, y : ymin },
			{ x : xmax, y : ymax },
			{ x : xmin, y : ymax } );
	}

	* fillRegionQuad( A, B, C, D )
	{
		const t1 = this.getT1();
		const t2 = this.getT2();
		const ttd = this.ttd;
		const aspects = this.aspects;

		let last_y;

		function bc( M, p ) {
			return { 
				x : M[0]*p.x + M[1]*p.y,
				y : M[2]*p.x + M[3]*p.y };
		};

		function sampleAtHeight( P, Q, y )
		{
			const t = (y-P.y)/(Q.y-P.y);
			return { x : (1.0-t)*P.x + t*Q.x, y : y };
		}

		function* doFill( A, B, C, D, do_top )
		{
			let x1 = A.x ;
			const dx1 = (D.x-A.x)/(D.y-A.y);
			let x2 = B.x;
			const dx2 = (C.x-B.x)/(C.y-B.y);
			const ymin = A.y;
			let ymax = C.y;
			if( do_top ) {
				ymax = ymax + 1.0;
			}

			let y = Math.floor( ymin );
			if( last_y ) {
				y = Math.max( last_y, y );
			}

			while( y < ymax ) {
				const yi = Math.trunc( y );
				let x = Math.floor( x1 );
				while( x < (x2 + 1e-7) ) {
					const xi = Math.trunc( x );

					for( let asp = 0; asp < ttd.num_aspects; ++asp ) {
						let M = aspects[ asp ].slice( 0 );
						//space between rows / columns of tiles
						M[2] += xi*t1.x + yi*t2.x;
						M[5] += xi*t1.y + yi*t2.y;

						yield {
							T : M,
							t1 : xi,
							t2 : yi,
							aspect : asp
						};
					}

					x += 1.0;
				}
				x1 += dx1;
				x2 += dx2;
				y += 1.0;
			}

			last_y = y;
		}

		function* fillFixX( A, B, C, D, do_top )
		{
			if( A.x > B.x ) {
				yield* doFill( B, A, D, C, do_top );
			} else {
				yield* doFill( A, B, C, D, do_top );
			}
		}

		function* fillFixY( A, B, C, D, do_top ) 
		{
			if( A.y > C.y ) {
				yield* doFill( C, D, A, B, do_top );
			} else {
				yield* doFill( A, B, C, D, do_top );
			}
		}

		const det = 1.0 / (t1.x*t2.y-t2.x*t1.y);
		const Mbc = [ t2.y * det, -t2.x * det, -t1.y * det, t1.x * det ];

		let pts = [ bc( Mbc, A ), bc( Mbc, B ), bc( Mbc, C ), bc( Mbc, D ) ];

		if( det < 0.0 ) {
			let tmp = pts[1];
			pts[1] = pts[3];
			pts[3] = tmp;
		}

		if( Math.abs( pts[0].y - pts[1].y ) < 1e-7 ) {
			yield* fillFixY( pts[0], pts[1], pts[2], pts[3], true );
		} else if( Math.abs( pts[1].y - pts[2].y ) < 1e-7 ) {
			yield* fillFixY( pts[1], pts[2], pts[3], pts[0], true );
		} else {
			let lowest = 0;
			for( let idx = 1; idx < 4; ++idx ) {
				if( pts[idx].y < pts[lowest].y ) {
					lowest = idx;
				}
			}

			let bottom = pts[lowest];
			let left = pts[(lowest+1)%4];
			let top = pts[(lowest+2)%4];
			let right = pts[(lowest+3)%4];

			if( left.x > right.x ) {
				let tmp = left;
				left = right;
				right = tmp;
			}

			if( left.y < right.y ) {
				const r1 = sampleAtHeight( bottom, right, left.y );
				const l2 = sampleAtHeight( left, top, right.y );
				yield* fillFixX( bottom, bottom, r1, left, false );
				yield* fillFixX( left, r1, right, l2, false );
				yield* fillFixX( l2, right, top, top, true );
			} else {
				const l1 = sampleAtHeight( bottom, left, right.y );
				const r2 = sampleAtHeight( right, top, left.y );
				yield* fillFixX( bottom, bottom, right, l1, false );
				yield* fillFixX( l1, right, r2, left, false );
				yield* fillFixX( left, r2, top, top, true );
			}
		}
	}
	
	getColour( a, b, asp )
	{
		const clrg = this.ttd.colouring;
		const nc = clrg[18];
		let mt1 = a % nc;
		if( mt1 < 0 ) {
			mt1 += nc;
		}
		let mt2 = b % nc;
		if( mt2 < 0 ) {
			mt2 += nc;
		}
		let col = clrg[asp];

		for( let idx = 0; idx < mt1; ++idx ) {
			col = clrg[12+col];
		}
		for( let idx = 0; idx < mt2; ++idx ) {
			col = clrg[15+col];
		}

		return col;
	}
};

export 
{
	EdgeShape,

	numTypes,
	tilingTypes,

	makePoint,
	makeMatrix,
	mul,
	matchSeg,

	IsohedralTiling
};
